import React from "react"

import { VehiclesPage } from "components/vehicles"

import { Audience } from "types/audience"

const Page = () => {
  return <VehiclesPage audience={Audience.admin} />
}

export default Page
